import React from "react"
import Link from "../link"

import Section from "../section"
import Column from "../column"
import TextBlock from "../text-block"
import BrandName from "../brand-name"
import Icon from "../../images/icon-package.svg"

export default ({ classes, id, text, children }) => (
    <Section classes={classes} id={id}>
        <div className="container text-large">
            <Column classes={['intro']}>
                <img src={Icon} alt="Grafik: Paket" width="160" />
                <TextBlock title="{b}Jetzt{/b} einsteigen.">
                    <p><BrandName /> bringt Vorteile für Sie als Praxisbetreiber, aber auch für die technischen Beteiligten. <strong>Jetzt einsteigen.</strong></p>
                    <Link to="/bestellen/" className="button">Jetzt bestellen</Link>
                </TextBlock>
            </Column>
        </div>
    </Section>
)
