import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default () => (
    <StaticQuery
        query={graphql`
            query {
                image: file(relativePath: { eq: "konnektor.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 744, quality: 90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            `}
            render={data => <Img fluid={data.image.childImageSharp.fluid} alt="Konnektor" />}
            />
    )
