import React from "react"
import {default as Link} from "../link"

import Section from "../section"
import Column from "../column"
import TextBlock from "../text-block"
import {default as Image} from "../images/terminal"

export default ({ classes, id, text, children }) => (
    <Section classes={classes} id={id}>
        <Column classes={['column--image']}>
            <Image />
        </Column>
        <Column classes={['column--copy']}>
            {children}
            <TextBlock title="Ein Terminal, {b}alle Karten.{/b}" classes={['text-block--limited']}>
                <p>Das stationäre eHealth-Terminal <strong>ORGA 6141 online</strong> von <Link to="https://ingenico.de" external={true}>ingenico</Link> erfüllt alle Anforderungen zu Sicherheit, Datenschutz und Geschwindigkeit beim Austauch von Versichertendaten mit dem Konnektor. Mit der <strong><Link to="https://www.cherry.de" external={true}>CHERRY</Link> eGK‑Tastatur G87‑1505</strong> bieten wir zudem eine platzsparende Alternative an.</p>
            </TextBlock>
        </Column>
    </Section>
)
