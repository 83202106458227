import React from "react"
import Link from "../components/link"

import Layout from "../components/layouts/default"
import SEO from "../components/global/seo"
import Header from "../components/header"
import Section from "../components/section"
import Column from "../components/column"
import Card from "../components/card"
import AvatarDVO from "../images/avatar-dvo.svg"
import AvatarDoc from "../images/avatar-doc.svg"

import SectionFeatures from "../components/sections/features-praxen"
import SectionKonnektor from "../components/sections/konnektor"
import SectionPartners from "../components/sections/partners"
import SectionTerminal from "../components/sections/terminal"
import SectionCTA from "../components/sections/order"

export default () => (
    <Layout className="wrapper--highlight">
        <SEO title="Telematik-Service für Ärzte &amp; Klinken – Jetzt Angebot anfordern" description="Mein Zugangsdienst bringt Ihre Praxis so effizient und schnell wie möglich in die Telematikinfrastruktur." />
        <article>
            <Header title="Gemacht für {b}Ärzte &amp; Kliniken.{/b}" image={<img src={AvatarDoc} alt="DVO" width="128" height="128" />} classes={['section highlight']}>
                <p>Für <strong>alle kassenärztlichen Praxen in Deutschland</strong> ist der Anschluss an die Telematikinfrastruktur des digitalen Gesundheitssystems verpflichtend. Wir haben es uns zur Aufgabe gemacht die notwendigen Hardware-Installationen in Ihrer Praxis so effizient und schnell wie möglich anzubieten.</p>
            </Header>
            <SectionFeatures classes={['section--alternate section--masked']} />
            <Section classes={['section--alternate']}>
                <Column classes={['narrow']}>
                    <Card title="Ihr Ansprechpartner" image={<img src={AvatarDVO} alt="DVO" width="128" height="128" />} classes={['card--inline card--horizontal']}>
                        <p>Die Bereitstellung und Installation erfolgt durch Ihren persönlichen Ansprechpartner und aus einer Hand: Kein Platz für Fingerzeigen. Unsere Dienstleister vor Ort (DVO) werden praxisnah geschult und sind mit den aktuellen Anforderungen der <Link to="https://www.gematik.de" external={true}>gematik</Link> vertraut.</p>
                    </Card>
                </Column>
            </Section>
            <SectionPartners classes={['section--masked']} text={true} id="partner" />
            <SectionKonnektor classes={['text-large section--masked section--vcenter section--alternate section--default section--image-bleed']} id="hardware" />
            <SectionTerminal classes={['text-large section--vcenter section--alternate section--default section--image-bleed']} />
            <SectionCTA classes={['section--masked section--alternate-2']} />
        </article>
    </Layout>
)
