import React from "react"

import Section from "../section"
import Column from "../column"
import TextBlock from "../text-block"
import Card from "../card"
import BrandName from "../brand-name"

import IconCertificate from "../../images/icon-certificate.svg"
import IconMoney from "../../images/icon-money.svg"
import IconBars from "../../images/icon-bars.svg"

export default ({ classes, id, text, children }) => (
    <Section classes={classes} id={id}>
        <div className="container">
            <Column classes={['intro']}>
                <TextBlock title="Zuverlässig und reibungslos {b}in die Telematik.{/b}">
                    <p>Mit <BrandName /> erhalten Sie den Zugang zur Telematikinfrastruktur als Gesamtpaket aus der Hand des Betreibers.</p>
                </TextBlock>
            </Column>
        </div>
        <div className="container">
            <div className="cards">
                <Column classes={['cards__column']}>
                    <Card title="Zertifizierte Technik" link={{ title: 'Wer steckt dahinter?', url: '/praxen/#partner' }} image={<img src={IconCertificate} alt="Grafik: Zertifikat" width="128" height="128" />} classes={['card--with-icon']}>
                        <p>Wir garantieren durch den Einsatz <strong>zertifizierter Hardware</strong> und von der gematik <strong>geprüfter Software</strong> eine schnelle und sichere Anbindung Ihrer Praxis an die Telematik-infrastruktur.</p>
                    </Card>
                </Column>
                <Column classes={['cards__column']}>
                    <Card title="Kostenerstattung" link={{ title: 'Mehr zur Kostenerstattung', url: 'https://www.kbv.de/html/30719.php', external: true }} image={<img src={IconMoney} alt="Grafik: Geldbörse" width="128" height="128" />} classes={['card--with-icon']}>
                        <p>Die Anbindung Ihrer Praxis an die <abbr title="Telematikinfrastruktur">TI</abbr> ist mit <BrandName /> für Sie kostendeckend realisierbar. Die Kosten werden <strong>bis Mitte 2019</strong> von den Krankenkassen erstattet. Die Erstattungshöhe hängt vom Zeitpunkt der Anbindung und der Anzahl der Ärzte in der Praxis ab.</p>
                    </Card>
                </Column>
                <Column classes={['cards__column']}>
                    <Card title="Leistungsstarker Konnektor" link={{ title: 'Mehr zum Konnektor', url: '/praxen/#hardware' }} image={<img src={IconBars} alt="Grafik: Leistungsvergleich" width="128" height="128" />} classes={['card--with-icon']}>
                        <p>Der leistungsstarke <BrandName /> Konnektor kann bis zu 50 Kartenterminals verwalten und sogar mehrere entfernte Praxis-Standorte miteinander vernetzen. Hochflexibel – ohne Mehrkosten.</p>
                    </Card>
                </Column>
            </div>
        </div>
    </Section>
)
