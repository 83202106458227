import React from "react"
import {default as Link} from "../link"

import Section from "../section"
import Column from "../column"
import TextBlock from "../text-block"
import {default as Image} from "../images/konnektor"

export default ({ classes, id, text, children }) => (
    <Section classes={classes} id={id}>
        <Column classes={['column--image']}>
            <Image />
        </Column>
        <Column classes={['column--copy']}>
            {children}
            <TextBlock title="{b}secunet{/b} konnektor" classes={['text-block--limited']}>
                <p>Der secunet konnektor ist von der <Link to="https://www.gematik.de" external={true}>gematik</Link> zertifiziert und bildet das technische Herzstück zur Verbindung der Praxis mit der TI. Er gewährleistet eine schnelle und sichere Anbindung sowie einen zukunftsfähigen Betrieb durch Erweiterungs&shy;möglichkeiten für künftige Anwendungen. Alle Konnektoren werden vorkonfiguriert ausgeliefert.</p>
            </TextBlock>
        </Column>
    </Section>
)
